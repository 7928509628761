<template>
  <div class="card mb-0" id="bg-current" :style="backgroundImg">
    <div id="bg-transparent"></div>
    <div class="card-body">
      <div class="row d-flex align-itens-center">
        <div class="col-10">
          <p class="mb-1">
            <small>{{ unity.emprName }}</small>
          </p>
          <p class="mb-0">{{ unity.unityName }}</p>
          <h6 class="mb-1">{{ unity.subUnityName }}</h6>
        </div>
        <div class="col-2 d-flex justify-content-center align-items-center">
          <router-link
            to="/users/unities"
            class="btn bg-secondary bg-opacity-10 text-secondary lh-1 rounded-pill p-2 me-2"
          >
            <i class="ph-repeat"></i>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped>
#bg-current {
  background-position: center;
  background-size: cover;
}

#bg-transparent {
  color-scheme: light;
  background-color: #fff;
}
#bg-transparent[data-color-theme='dark'],
[data-color-theme='dark'] #bg-transparent:not([data-color-theme]),
html[data-color-theme='dark'] .card {
  color-scheme: dark;
  background-color: #2c2d33;
}
#bg-transparent {
  color-scheme: light;
  border-radius: var(--card-border-radius);
  opacity: 0.9;
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  z-index: 0;
}
.card-body {
  z-index: 2;
}
</style>
<script>
export default {
  name: 'CurrentUnity',

  props: {},

  data() {
    return {
      backgroundImg: {
        'background-image': `url('/img/enterprises/${this.$store.getters.unity.codPessEmpr}.png')`,
      },
    }
  },

  methods: {},
  computed: {
    unity() {
      return this.$store.getters.unity
    },
  },
  mounted() {},
}
</script>
