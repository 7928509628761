<template>
  <div class="h-100">
    <div class="row p-4">
      <div class="col-12">
        <Logo />
        <h2 class="text-center">Seja bem-vindo!</h2>
      </div>
      <div class="col-12 col-md-6">
        <div class="card">
          <div class="card-header d-flex align-items-center">
            <h6 class="mb-0">Selecionar Unidade</h6>
            <div class="ms-auto">
              <router-link to="/users/unities">
                <span class="badge bg-secondary text-white"
                  >Trocar Unidade</span
                >
              </router-link>
            </div>
          </div>
          <CurrentUnity />
        </div>
      </div>
      <div class="col-12 col-md-6" v-if="unity.showFinancial">
        <div class="card">
          <div class="card-header d-flex align-items-center">
            <h6 class="mb-0">Posição Financeira</h6>
            <div class="ms-auto">
              <router-link to="/financials">
                <span class="badge bg-secondary text-white"
                  >Acessar Financeiro</span
                >
              </router-link>
            </div>
          </div>
          <GeneralChart />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import CurrentUnity from '@/components/unity/CurrentUnity.vue'
import Logo from '@/components/commons/Logo.vue'
import GeneralChart from '@/components/financials/GeneralChart.vue'

export default {
  name: 'Home',
  components: {
    CurrentUnity,
    GeneralChart,
    Logo,
  },
  data() {
    return {
      unity: this.$store.getters.unity,
    }
  },
}
</script>
