<template>
  <div class="card">
    <div
      class="card-header d-flex align-items-center justify-content-between py-0"
    >
      <div>
        <router-link to="/"><i class="ph-caret-left"></i></router-link>
      </div>
      <h6 class="py-3 mb-0 text-secondary">
        <i class="ph-file-text me-2" /> Documentos
      </h6>
      <Popper>
        <button type="button" class="btn btn-link">
          <i class="ph-info ms-2"></i>
        </button>
        <template #content>
          <h6>Documentos</h6>
          <p>
            Exibe toda documentação relacionada a sua compra e também a sua
            unidade
          </p>
        </template>
      </Popper>
    </div>
    <LoadingData v-if="loadData"></LoadingData>
    <div v-else class="card-body">
      <h5 class="fw-light text-primary">Documentos Pessoais</h5>
      <div
        class="d-flex"
        v-for="document in documents.client"
        :key="document.documentUuid"
      >
        <div><i class="ph-file-text me-2" /> {{ document.documentName }}</div>
        <a
          :href="`${documents.url}${document.documentUuid}.${document.documentFileExt}`"
          target="_blank"
          class="btn btn-link btn-sm text-primary ms-auto"
          >Visualizar</a
        >
      </div>
      <div v-if="documents.client && documents.client.length === 0">
        <p>Nenhum documento cadastrado.</p>
      </div>
      <h5 class="fw-light text-primary">Documentos do Empreendimento</h5>
      <div
        class="d-flex"
        v-for="document in documents.empr"
        :key="document.documentUuid"
      >
        <div><i class="ph-file-text me-2" /> {{ document.documentName }}</div>
        <a
          :href="`${documents.url}${document.documentUuid}.${document.documentFileExt}`"
          target="_blank"
          class="btn btn-link btn-sm text-primary ms-auto"
          >Visualizar</a
        >
      </div>
      <div v-if="documents.empr && documents.empr.length === 0">
        <p>Nenhum documento cadastrado.</p>
      </div>
    </div>
  </div>
</template>

<script>
import Popper from 'vue3-popper'
import LoadingData from '@/components/commons/LoadingData.vue'

export default {
  name: 'Documents',

  data() {
    return {
      documents: [],
      loadData: true,
    }
  },
  components: {
    Popper,
    LoadingData,
  },
  methods: {
    async getDocuments() {
      try {
        this.documents = await this.$store.dispatch('getDocuments')
        this.loadData = false
      } catch (error) {
        if (error && error.message) {
          this.$root.$refs.notification.warning(error.message)
        } else {
          this.$root.$refs.notification.error(
            'Não foi possível se conectar ao servidor.',
          )
        }
      }
    },
  },

  mounted() {
    this.getDocuments()
  },
}
</script>
